import React from "react";

const CheckIcon = (props) => {
	return (
		<svg viewBox="0 0 24 24" fill="none" {...props}>
			<circle cx={12} cy={12} r={12} fill="#fff" opacity="1" />
			<path
				d="M7 13l3 3 7-7"
				stroke="#6366f1"
				strokeWidth={2}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default CheckIcon;
